/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'material-icons/iconfont/material-icons.css';
.tsn-card {
  color: greenyellow;
  font-size: 22px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: unset !important;
}
body {
  /*padding: 0px 10px 10px 10px;*/
  max-width: 1000px;
  margin: auto;

}

@media screen and (max-width: 500px) /* Mobile */ {
  body {

    /*max-width: 500px;*/
    zoom: 80%;
  }
}


.fullwidth,
.full-width,
.full_width {
  width: 100%;
}




.f,
.color-for{
 background-color:  rgb(0, 128, 0);

}

.a,
.color-against{
  background-color:  rgb(255, 0, 0);
}
.vertical-splitter{
  height: calc(100% - 10px);
  border-left: 1px white solid;
  margin: 5px 10px;
}
.r,
.color-abstained{
  background-color:  rgb(100, 149, 237);;
}

.d,
.color-rejection{
  background-color:  rgb(128, 128, 128);;
}

.ok-panel{
  background: lightgreen;
  color: black;
}

.error-panel,
.exception-panel{
  background: red;
  color: white;
}
.warning-panel{
  background: grey;
  color: azure;
}

.layout-padding,
.header,
.page__item,
.footer {
  padding-right: 0;
  padding-left: 0;
}
table{
  border-collapse: collapse;
}

.status{
  background: #d3d3d359;
  border: aquamarine 1px solid;
  border-radius: 6px;
  padding: 5px;
}

.status mat-icon{
  font-size: 22px;
  vertical-align: middle;
}

.status .status-title{

}

.animated {
  -webkit-transition: all .5s cubic-bezier(0.32, 0.44, 0.57, 1.07);
  -ms-transition: all .5s cubic-bezier(0.32, 0.44, 0.57, 1.07);
  transition: all .5s cubic-bezier(0.32, 0.44, 0.57, 1.07);
}

.align-x-center {
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
}
